import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonDataService } from './../../common-data.service';
import { StatusDosAgendamentosService } from './../../api/services/status-dos-agendamentos.service';
import { ModalService } from '../../shared/_services/index';
import { ToastsService } from './../../api/toasts/toasts.service';
declare var swal: any;

@Component({
  selector: 'app-status-dos-agendamentos',
  templateUrl: './status-dos-agendamentos.component.html',
  styleUrls: ['./status-dos-agendamentos.component.scss']
})
export class StatusDosAgendamentosComponent implements OnInit {
	isFormLoading: boolean = false;
	tableSettings: any = {
		columns: [
			// { 
			// 	name: 'Código',
			// 	prop: 'codigo'
			// },
			{ 
				name: 'Nome',
				prop: 'nome'
			},
			{ 
				name: 'Descrição',
				prop: 'descricao'
			}
		],
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true
	}

	pageTitle: any = {
		title: 'Status dos Agendamentos',
		breadcumbs: [
		{
			title: 'Dashboard',
			href: '/'
		}
		],
		breadcumbActive: 'Status dos Agendamentos'
	}

	form: FormGroup;
	selected: any = {};
	isCreate: boolean = false;

	constructor(private _commondata: CommonDataService,
							private service: StatusDosAgendamentosService, 
							private modalService: ModalService,
							private fb: FormBuilder,
							private toastsService: ToastsService)
	{
		this.form = this.fb.group({  
			nome: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]),
			descricao: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
		});
	}

	ngOnInit() {
		this.load();
	}

	load() {
		this.service.get().subscribe((data: any[]) => {
			this.tableSettings.temp = data;
			this.tableSettings.filterrows = data;
			this.tableSettings.isLoading = false;
			this.isFormLoading = false;
		});
	}

	addNew() {
		this.isCreate = true;
		this.form.reset();
		this.selected = {}
		this.modalService.open('modal');
	}

	onSelect(item) {
		this.isCreate = false;
		this.selected = item;
		this.form.reset();
		this.form.patchValue(item);
		this.modalService.open('modal');
	}

	onReload() {
		this.tableSettings.isLoading = true;
		this.load();
	}

	updateFilter(val) {
		const temp = this.tableSettings.temp.filter(function (d) {
			return (d.nome.toLowerCase().indexOf(val) !== -1 || 
				d.descricao.toLowerCase().indexOf(val) !== -1 ||
				String(d.codigo).indexOf(val) !== -1 ) 
			|| !val;
		});

		this.tableSettings.filterrows = temp;
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	submit() {
		if(this.form.valid) {
			this.isFormLoading = true;
			if(this.isCreate) {
				this.service.post(this.form.value).subscribe((data: any) => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Salvo com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
			else {
				this.service.patch(this.form.value, this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Editado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}

		}
		else {
			this.validateAllFormFields(this.form);
		}
	}

	remove() {
		swal({
			text: 'Deseja realmente deletar esse Status de Agendamento?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Não!',
			confirmButtonText: 'Sim, deletar!'
		}).then((result) => {
			if (result.value) {
				this.isFormLoading = true;
				this.service.delete(this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Deletado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
		});
	}

	campoValido(nomeCampo) {
		return !this.form.get(nomeCampo).valid && (this.form.get(nomeCampo).dirty || this.form.get(nomeCampo).touched)
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => { 
			const control = formGroup.get(field);            
			if (control instanceof FormControl) {            
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {       
				this.validateAllFormFields(control);           
			}
		});
	}
}
