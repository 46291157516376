import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
declare var document: any;

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent implements OnInit {
  @Input() menu: any;

  constructor() {}

  ngOnInit() {}

  expandCollpse(sectionName, e) {
    e.stopPropagation();
    e.preventDefault();
    var CurrentCls = document.getElementById(sectionName).getAttribute('class');
    if (CurrentCls == 'collapse' || CurrentCls == 'collapse hide')
    {
      document.getElementById(sectionName).setAttribute('class', 'collapse show');
      document.getElementById(sectionName).previousElementSibling.setAttribute('aria-expanded', 'true');
    }
    else {
      document.getElementById(sectionName).setAttribute('class', 'collapse hide');
      document.getElementById(sectionName).previousElementSibling.setAttribute('aria-expanded', 'false');
    }
  }

  formataLabel(nome) {
    var str = nome.replace(/[ÀÁÂÃÄÅ]/g,"A");
    str = str.replace(/[àáâãäå]/g,"a");
    str = str.replace(/[ÈÉÊË]/g,"E");

    return str.replace(/[^a-z0-9]/gi,'');
  }

  sortBy(data, prop) {
    if(data)
      return data.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }
}
