import { Component, OnInit } from '@angular/core';
import { UserService } from './../api/services/user.service';
import { ToastsService } from './../api/toasts/toasts.service';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators, NgForm, FormsModule } from '@angular/forms';

@Component({
	selector: 'app-alterarsenha',
	templateUrl: './alterarsenha.component.html',
	styleUrls: ['./alterarsenha.component.scss']
})

export class AlterarsenhaComponent implements OnInit {
	form: FormGroup;
	isLoading: boolean = false;
	signupForm: FormGroup;
	token: string
	public randomImg: string = `url(assets/images/bg/bg0${Math.floor(Math.random() * (5 - 1 + 1)) + 1}.jpg)`

	constructor(
		private userService: UserService,
		private toastsService: ToastsService, 
		private router: Router,
		private fb: FormBuilder,
		private route: ActivatedRoute) {
		this.form = this.fb.group({  
			senha: new FormControl('', [Validators.required,
				Validators.pattern('(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}'), Validators.minLength(8)]),
			confirmaSenha: new FormControl('', [Validators.required,
				Validators.pattern('(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}'), Validators.minLength(8)]),
		});
		this.route.queryParams.subscribe(params => {
			this.token = params['d'];
		});
	}

	ngOnInit() {
	}

	validSenha(field, type) {
		switch (type) {
			case "maiuscula":
				return /[A-Z]/.test(this.form.get(field).value);
				break;
			case "minuscula":
				return /[a-z]/.test(this.form.get(field).value);
				break;
			case "numero":
				return /.*[0-9].*/.test(this.form.get(field).value);
				break;
			case "especial":
				return /.*[!@#\$%\^\&*\)\(+=._-].*/.test(this.form.get(field).value);
				break;
			default:
				break;
		}
	}

	alterarSenha() {
		this.isLoading = true;
		console.log(this.form.valid)
		if(this.form.valid && this.form.controls.senha.value == this.form.controls.confirmaSenha.value) {
			this.userService.updatePassword(this.token, this.form.controls.senha.value).subscribe(data => {
				this.toastsService.toastSuccess('Senha alterada com sucesso!');
				this.router.navigate(['/login']);
			}, data => {
				this.isLoading = false;
				this.toastsService.toastError('Não foi possível alterar sua senha, tente novamente ou entre em contato com nosso time!');
			})
		}
		else {
			this.isLoading = false;
			this.toastsService.toastError("Senha inválida!");
		}
	}
}
