import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})

export class ProgramacaoService {

	constructor(private http: HttpClient) { }

	private authHeader(): string {
		return `Bearer ${JSON.parse(localStorage.getItem('User')).token}`;
	}

	public get(CodFuncionario) {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.get(`${environment.apiUrl}/cadastro/programacao?CodFuncionario=${CodFuncionario}`, { headers });
	}

	public post(body) {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.post(`${environment.apiUrl}/cadastro/programacao`, body, { headers });
	}

	public patch(body, id) {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.patch(`${environment.apiUrl}/cadastro/programacao/${id}`, body, { headers });
	}

	public delete(id) {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.delete(`${environment.apiUrl}/cadastro/programacao/${id}`, { headers });
	}
}
