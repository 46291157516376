import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from './../../../api/services/menu.service';


declare var document: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  menus: any = []

  constructor(
    private router: Router,
    private menuService: MenuService) {
  }

  ngOnInit() {
    if(localStorage.getItem('User')) {
      this.menuService.get().subscribe((data: any[]) => {
        this.menus = this.sortBy(data, 'ordem');
        this.menuService.setMenu(data);
      });
    }
  }

  sortBy(data, prop) {
    return data.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }
}
