import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})

export class MenuService {
	menu = false

	constructor(private http: HttpClient) { }

	private authHeader(): string {
		return `Bearer ${JSON.parse(localStorage.getItem('User')).token}`;
	}

	public get() {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.get(`${environment.apiUrl}/sistema/Controllers/Menu?CodVisibilidadeController=2`, { headers });
	}

	public setMenu(data: any) {
		this.menu = data;
		localStorage.setItem('Menu', JSON.stringify(data));
	}

	public getMenu() {
		let menu = JSON.parse(localStorage.getItem('Menu'));
		this.menu = menu;
		return menu;
	}
}
