import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RotasService } from './../../api/services/rotas.service';

import { DashboardComponent } from './../../dashboard/dashboard.component';
import { LayoutComponent } from './../../shared/_layout/layout/layout.component';
import { LoginComponent } from './../../login/login.component';
import { AlterarsenhaComponent } from './../../alterarsenha/alterarsenha.component'

import { AreasComponent } from './../../components/areas/areas.component';
import { CategoriaDeAtividadesComponent } from './../../components/categoria-de-atividades/categoria-de-atividades.component';
import { AtividadesComponent } from './../../components/atividades/atividades.component';
import { CategoriaDeUnidadesComponent } from './../../components/categoria-de-unidades/categoria-de-unidades.component';
import { TiposDeServicoComponent } from './../../components/tipos-de-servico/tipos-de-servico.component';
import { FuncionariosComponent } from './../../components/funcionarios/funcionarios.component';
import { HospedesComponent } from './../../components/hospedes/hospedes.component';
import { ServicosComponent } from './../../components/servicos/servicos.component';
import { UnidadesDeHabitacaoComponent } from './../../components/unidades-de-habitacao/unidades-de-habitacao.component';
import { StatusDosAgendamentosComponent } from './../../components/status-dos-agendamentos/status-dos-agendamentos.component';
import { ChecklistComponent } from './../../components/checklist/checklist.component';
import { DepartamentosComponent } from './../../components/departamentos/departamentos.component';
import { TarefasComponent } from './../../components/tarefas/tarefas.component';
import { ProgramacaoComponent } from './../../components/programacao/programacao.component';
import { ReservasComponent } from './../../components/reservas/reservas.component';
import { CheckoutComponent } from './../../components/checkout/checkout.component';
import { ExcecoesComponent } from './../../components/excecoes/excecoes.component';
import { UsuariosComponent } from './../../usuarios/usuarios.component';
import { AgendamentosComponent } from './../../components/agendamentos/agendamentos.component';

@Injectable()
export class AppInitService {
   component: any = {};

  constructor(private rotasService: RotasService) {
    this.instanceComponents();
  }

  initializeApp(injector): Promise<any> {
    return new Promise((resolve, reject) => {
      let router: Router = injector.get(Router);

      if(localStorage.getItem('User')) {
        this.rotasService.get().subscribe((data: any[]) => {
          for (var i = 0; i < data.length; ++i) {
            if(this.component[data[i].nome]) {
              router.config[0].children.push(
                { 
                  path: data[i].rota, 
                  component: this.component[data[i].nome],
                  pathMatch: 'full'
                });
            }
          }

          router.resetConfig(router.config);
          resolve();
        }, error => {
          resolve();
        });
      }
      else{
        resolve();
      };
    });
  }

  instanceComponents() {
    this.component['Local'] = AreasComponent;
    this.component['Atividade'] = AtividadesComponent;
    this.component['CategoriaAtividade'] = CategoriaDeAtividadesComponent;
    this.component['CategoriasUnidade'] = CategoriaDeUnidadesComponent;
    this.component['TiposServico'] = TiposDeServicoComponent;
    this.component['Funcionarios'] = FuncionariosComponent;
    this.component['Hospedes'] = HospedesComponent;
    this.component['Servicos'] = ServicosComponent;
    this.component['UnidadesHabitacao'] = UnidadesDeHabitacaoComponent;
    this.component['StatusAgendamento'] = StatusDosAgendamentosComponent;
    this.component['CheckList'] = ChecklistComponent;
    this.component['Departamentos'] = DepartamentosComponent;
    this.component['Tarefas'] = TarefasComponent;
    this.component['Programacao'] = ProgramacaoComponent;
    this.component['Reservas'] = ReservasComponent;
    this.component['RelatorioReservas'] = CheckoutComponent;
    this.component['SolicitacaoServico'] = ExcecoesComponent;
    this.component['Usuarios'] = UsuariosComponent;
    this.component['Agendamento'] = AgendamentosComponent;
  }
 }