import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { nextTick } from 'process';
import { retryWhen, delay, take, tap } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { ToastsService } from '../toasts/toasts.service';
import { CommonDataService } from '../../common-data.service';
import { UserService } from '../services/user.service';


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	public toastAberto: boolean = false

	constructor(private router: Router, 
							private toastsService: ToastsService,
							private userService: UserService,
							private _commondata: CommonDataService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			retryWhen(err => {
				var countTry = 0;
				return err.pipe(delay(600), take(3), tap(errorStatus => {
					countTry++;
					if(errorStatus.status == 401) {
						var user = this.userService.getUser();

						// if(user) {
						// 	this.userService.refreshToken(user.user, user.token, user.refreshToken).subscribe((data: any) => {
				  //       		this.userService.setUser(data);
						// 	}, error => {
						// 		this.tratarErro(errorStatus);
						// 		localStorage.clear();
						// 		this.router.navigate(['/login']);
						// 		throw errorStatus;
						// 	});
						// }
						// else {
							this.tratarErro(errorStatus);
							localStorage.clear();
							this.router.navigate(['/login']);
							throw errorStatus;
						// }
					}
					else if (errorStatus.status == 500 && countTry == 3) {
						this.tratarErro(errorStatus);
						throw errorStatus;
					}
					else if(countTry == 3) {
						this.tratarErro(errorStatus);
						throw errorStatus;
					}
				}))
			})
		);
	}

	private async tratarErro(error) {
		this._commondata.showLoader(false);
		if(!this.toastAberto) {
			this.toastAberto = true;
			const statusMessage = error.status == 401 ? 'Sessão expirada, entre novamente!' : 'Ocorreu um erro durante o processamento.';
			this.toastsService.toastError(statusMessage);
		}
	}
}