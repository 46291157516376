import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { CommonDataService } from './../../../common-data.service';
import { UserService } from './../../../api/services/user.service';
import { ToastsService } from './../../../api/toasts/toasts.service';
import { RotasService } from './../../../api/services/rotas.service';


declare var document: any;

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    isSearchActive:boolean = false;
    isSlideMenu:boolean = false;
    user: any
    pesquisas: any = []
    pesquisas_temp: any = []

    constructor(
        public _commondata: CommonDataService, 
        private userService: UserService, 
        private router: Router,
        private toastsService: ToastsService,
        private rotasService: RotasService) {
    }
    
    ngOnInit() {
      this.user = this.userService.getUser();
      if(!this.user) {
          this.router.navigate(['/login']);
      }

      this.rotasService.get().subscribe((data: any[]) => {
        // Removendo rotas não navegáveis
        data = data.filter(pesquisa => pesquisa.rota != "" && pesquisa.rota != "#");

        this.pesquisas = data;
        this.pesquisas_temp = data;
      })
    }

    redireciona_pesquisa(rota) {
      this.router.navigate([rota]);
    }

    search(text) {
      const temp = this.pesquisas_temp.filter(function (d) {
        return (d.label.toLowerCase().indexOf(text) !== -1 || 
          d.nome.toLowerCase().indexOf(text) !== -1 ||
          d.rota.toLowerCase().indexOf(text) !== -1 ) || !text;
      });

      this.pesquisas = temp;
    }

    logOut() {
        localStorage.removeItem('User');
        localStorage.removeItem('Menu');
        this.router.navigate(['/login']);
    }

    toggleSearch(){
        this.isSearchActive = !this.isSearchActive;
        if(this.isSearchActive) {
          setTimeout(() => {
            $(".not-click-search").focus();
          }, 500);
        }
    }
    
    toggleMenu(){
        this.isSlideMenu = !this.isSlideMenu;
    }

    expandCollpse(sectionName) {
        var CurrentCls = document.getElementById(sectionName).getAttribute('class');
        if (CurrentCls == 'collapse' || CurrentCls == 'collapse hide')
        {
            document.getElementById(sectionName).setAttribute('class', 'collapse show');
            document.getElementById(sectionName).previousElementSibling.setAttribute('aria-expanded', 'true');
        }
        else {
            document.getElementById(sectionName).setAttribute('class', 'collapse hide');
            document.getElementById(sectionName).previousElementSibling.setAttribute('aria-expanded', 'false');
        }
    }

    toggleFullscreen(elem) {
        elem = elem || document.documentElement;
        if (!document.fullscreenElement && !document['mozFullScreenElement'] &&
            !document.webkitFullscreenElement && !document['msFullscreenElement']) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element['ALLOW_KEYBOARD_INPUT']);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document['msExitFullscreen']) {
                document['msExitFullscreen']();
            } else if (document['mozCancelFullScreen']) {
                document['mozCancelFullScreen']();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }
}
