import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutComponent } from './shared/_layout/layout/layout.component';
import { LoginComponent } from './login/login.component';
import { AlterarsenhaComponent } from './alterarsenha/alterarsenha.component';

// As rotas são construídas no arquivo app-init-service.ts

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: DashboardComponent, pathMatch: 'full' },
    ]
  },

  { path: 'login', component: LoginComponent },
  { path: 'alterarsenha', component: AlterarsenhaComponent },
  {
    path: "**", 
    redirectTo: ""
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
