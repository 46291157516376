import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../shared/_services/index';
import { ToastsService } from './../api/toasts/toasts.service';
import { UserService } from './../api/services/user.service';
import { GruposDeAcessoService } from './../api/services/grupos-de-acesso.service';
import { ControllersService } from './../api/services/controllers.service';
import * as $ from 'jquery';
declare var swal: any;

@Component({
	selector: 'app-usuarios',
	templateUrl: './usuarios.component.html',
	styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
	isFormLoading: boolean = false;
	tableSettings: any = {
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true
	}

	tableSettingsGrupos: any = {
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true
	}

	tableSettingsPermissoes: any = {
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true
	}

	tableSettingsUsuariosGrupo: any = {
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true
	}

	formGrupo: FormGroup;
	formUsuario: FormGroup;
	selected: any = {};
	selectedUsuario: any = {};
	isCreate: boolean = true;

	codAtividades: any = []
	usuarios: any = []

	constructor(private userService: UserService,
		private gruposDeAcessoService: GruposDeAcessoService,
		private modalService: ModalService,
		private controllersService: ControllersService,
		private fb: FormBuilder,
		private toastsService: ToastsService) {
		this.formUsuario = this.fb.group({
			// nome: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]),
			nome: new FormControl('', [Validators.maxLength(30)]),
			email: new FormControl('', [Validators.required, Validators.email])
		});

		this.formGrupo = this.fb.group({
			nome: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]),
			descricao: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)])
		});
	}

	ngOnInit() {
		this.loadUsuarios();
		this.loadGrupos();
		this.loadPermissoes();
	}

	loadUsuarios() {
		this.userService.get().subscribe((data: any[]) => {
			this.tableSettings.temp = data;
			this.tableSettings.filterrows = data;
			this.tableSettings.isLoading = false;
			this.isFormLoading = false;
		});
	}

	loadGrupos() {
		this.gruposDeAcessoService.get().subscribe((data: any[]) => {
			this.tableSettingsGrupos.temp = data;
			this.tableSettingsGrupos.filterrows = data;
			this.tableSettingsGrupos.isLoading = false;
		});
	}

	loadPermissoes() {
		this.controllersService.get().subscribe((data: any[]) => {
			this.tableSettingsPermissoes.temp = data;
			this.tableSettingsPermissoes.filterrows = data;
			this.tableSettingsPermissoes.isLoading = false;
		});
	}

	// addNew() {
	// 	this.isCreate = true;
	// 	this.form.reset();
	// 	this.selected = {}
	// 	this.modalService.open('modal');
	// }

	onSelect(event) {
		// if(event.type == 'click') {
		//     console.log(event.row);
		// }
	}

	novoUsuario() {
		$(".ngx-datatable datatable-body datatable-body-row").removeClass("datatable-row-selected")
		this.isCreate = true;
		this.formUsuario.reset();
	}

	onSelectUsuario(event) {
		if (event.type == 'click') {
			$(event.rowElement).parent().siblings().toArray().forEach((element) => {
				$($(element).children().toArray()[0]).removeClass("datatable-row-selected")
			})

			$(event.rowElement).addClass("datatable-row-selected")
			this.isCreate = false;
			this.selectedUsuario = event.row;
			this.formUsuario.reset();
			this.formUsuario.patchValue(event.row);
		}
	}

	novoGrupo() {
		$(".ngx-datatable datatable-body datatable-body-row").removeClass("datatable-row-selected")
		this.isCreate = true;
		this.formGrupo.reset();
		this.tableSettingsUsuariosGrupo.temp = [];
		this.tableSettingsUsuariosGrupo.filterrows = [];
		this.selected = {};
		this.tableSettingsPermissoes.temp = [];
		this.tableSettingsPermissoes.filterrows = [];
	}

	onSelectGrupo(event) {
		if (event.type == 'click') {
			this.onReloadPermissoes();
			
			$(event.rowElement).parent().siblings().toArray().forEach((element) => {
				$($(element).children().toArray()[0]).removeClass("datatable-row-selected")
			})

			$(event.rowElement).addClass("datatable-row-selected")
			
			this.isCreate = false;
			this.selected = event.row;
			this.formGrupo.reset();
			this.formGrupo.patchValue(event.row);
			this.tableSettingsUsuariosGrupo.temp = this.tableSettings.temp;
			this.tableSettingsUsuariosGrupo.filterrows = this.tableSettings.temp;
		}
	}

	onReloadUsuarios() {
		this.tableSettings.isLoading = true;
		this.loadUsuarios();
	}

	onReloadGrupos() {
		this.tableSettingsGrupos.isLoading = true;
		this.loadGrupos();
	}

	onReloadPermissoes() {
		this.tableSettingsPermissoes.isLoading = true;
		this.loadPermissoes();
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.tableSettingsPermissoes.temp.filter(function (d) {
			return (d.nome.toLowerCase().indexOf(val) !== -1 || !val);
		});

		this.tableSettingsPermissoes.filterrows = temp;
	}

	updateFilterUsuario(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.tableSettings.temp.filter(function (d) {
			return (d.email.toLowerCase().indexOf(val) !== -1 ||
				(d.nome && d.nome.toLowerCase().indexOf(val) !== -1) ||
				String(d.codUsuario).indexOf(val) !== -1 ||
				!val);
		});

		this.tableSettings.filterrows = temp;
	}

	updateFilterGrupos(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.tableSettingsGrupos.temp.filter(function (d) {
			return (d.nome.toLowerCase().indexOf(val) !== -1 ||
				d.descricao.toLowerCase().indexOf(val) !== -1 ||
				String(d.codGrupoAcesso).indexOf(val) !== -1) || !val;

		});
		this.tableSettingsGrupos.filterrows = temp;
	}

	updateFilterGruposUsuarios(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.tableSettingsUsuariosGrupo.temp.filter(function (d) {
			return (d.email.toLowerCase().indexOf(val) !== -1 ||
				String(d.codUsuario).indexOf(val) !== -1 || !val);
		});

		this.tableSettingsUsuariosGrupo.filterrows = temp;
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	submit() {
		if (this.formUsuario.valid) {
			this.isFormLoading = true;
			if (this.isCreate) {
				var body = this.formUsuario.value;
				body.ativo = true;
				body.senha = this.randomPassword(20);
				this.userService.post(this.formUsuario.value).subscribe((data: any) => {
					this.toastsService.toastSuccess("Salvo com sucesso!");
					this.loadUsuarios();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
			else {
				this.userService.patch(this.formUsuario.value, this.selectedUsuario.codUsuario).subscribe(() => {
					this.toastsService.toastSuccess("Editado com sucesso!");
					this.loadUsuarios();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}

		}
		else {
			this.validateAllFormFields(this.formUsuario);
		}
	}

	randomPassword(length) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}
		return result;
	}

	submitGrupo() {
		if (this.formGrupo.valid) {
			this.isFormLoading = true;
			if (this.isCreate) {
				var body = this.formGrupo.value;
				body.ativo = true;
				this.gruposDeAcessoService.post(this.formGrupo.value).subscribe((data: any) => {
					this.toastsService.toastSuccess("Salvo com sucesso!");
					this.loadGrupos();
					this.isFormLoading = false;
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
			else {
				this.gruposDeAcessoService.patch(this.formGrupo.value, this.selected.codGrupoAcesso).subscribe(() => {
					this.toastsService.toastSuccess("Editado com sucesso!");
					this.loadGrupos();
					this.isFormLoading = false;
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
		}
		else {
			this.validateAllFormFields(this.formGrupo);
		}
	}

	// remove() {
	// 	swal({
	// 		text: 'Deseja realmente deletar essa Categoria de Atividade?',
	// 		type: 'warning',
	// 		showCancelButton: true,
	// 		confirmButtonColor: '#d33',
	// 		cancelButtonColor: '#3085d6',
	// 		cancelButtonText: 'Não!',
	// 		confirmButtonText: 'Sim, deletar!'
	// 	}).then((result) => {
	// 		if (result.value) {
	// 			this.isFormLoading = true;
	// 			this.service.delete(this.selected.codigo).subscribe(() => {
	// 				this.closeModal('modal');
	// 				this.toastsService.toastSuccess("Deletado com sucesso!");
	// 				this.loadUsuarios();
	// 			}, error => {
	// 				this.isFormLoading = false;
	// 				this.toastsService.toastError("Tente novamente!");
	// 			});
	// 		}
	// 	});
	// }

	campoValido(nomeCampo, form) {
		return !form.get(nomeCampo).valid && (form.get(nomeCampo).dirty || form.get(nomeCampo).touched)
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}

	usuarioPertenceAoGrupo(usuario, usuarios) {
		var pertenceAoGrupo = usuarios.filter(grupo => grupo.codUsuario == usuario.codUsuario);

		return pertenceAoGrupo.length > 0 ? true : false;
	}

	adicionaRemoveUsuarioAoGrupo(usuario, grupo) {
		if (this.usuarioPertenceAoGrupo(usuario, grupo.usuarios)) {
			usuario.gruposAcesso = usuario.gruposAcesso.filter(gru => gru.codGrupoAcesso != grupo.codGrupoAcesso);
		}
		else {
			usuario.gruposAcesso.push(grupo)
		}

		this.userService.patch(usuario, usuario.codUsuario).subscribe(() => {
			this.toastsService.toastSuccess("Editado com sucesso!");
		}, error => {
			this.isFormLoading = false;
			this.toastsService.toastError("Revise todos os dados e tente novamente!");
		});
	}

	atualizaAtivoUsuario(usuario) {
		usuario.ativo = !usuario.ativo
		var body = {
			ativo: usuario.ativo
		}
		this.userService.patch(body, usuario.codUsuario).subscribe(() => {
			this.toastsService.toastSuccess("Editado com sucesso!");
		}, error => {
			this.isFormLoading = false;
			if (typeof error.error === 'string') {
				this.toastsService.toastError(error.error);
			}
			else if (error.error.errors) {
				let errorMessage = ""
				Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
					errorMessage += error.error.errors[field]
				})

				this.toastsService.toastError(errorMessage);
			}
			else {
				this.toastsService.toastError("Revise todos os dados e tente novamente!");
			}
		});
	}

	atualizaAtivoGrupo(grupo) {
		grupo.ativo = !grupo.ativo
		var body = {
			ativo: grupo.ativo
		}
		this.gruposDeAcessoService.patch(body, grupo.codGrupoAcesso).subscribe(() => {
			this.toastsService.toastSuccess("Editado com sucesso!");
		}, error => {
			this.isFormLoading = false;
			if (typeof error.error === 'string') {
				this.toastsService.toastError(error.error);
			}
			else if (error.error.errors) {
				let errorMessage = ""
				Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
					errorMessage += error.error.errors[field]
				})

				this.toastsService.toastError(errorMessage);
			}
			else {
				this.toastsService.toastError("Revise todos os dados e tente novamente!");
			}
		});
	}

	atualizaPermissao(controller, method) {
		var action = controller.actions.filter(action => {
			return action.nome == method;
		});

		var grupoDeAcesso = action[0].gruposAcesso.filter(grupo => {
			return grupo.codGrupoAcesso == this.selected.codGrupoAcesso;
		});

		if (grupoDeAcesso.length > 0) {
			this.controllersService.delete(this.selected.codGrupoAcesso, action[0].codActionController).subscribe(() => {
				this.toastsService.toastSuccess("Editado com sucesso!");
				this.tableSettingsPermissoes.isLoading = true;
				this.loadPermissoes();
			}, error => {
				this.toastsService.toastError("Ocorreu um erro ao processar a solicitação, tente novamente mais tarde.");
			});
		}
		else {
			var body = {
				codGrupoAcesso: this.selected.codGrupoAcesso,
				codActionController: action[0].codActionController
			}
			this.controllersService.post(body).subscribe(() => {
				this.toastsService.toastSuccess("Editado com sucesso!");
				this.tableSettingsPermissoes.isLoading = true;
				this.loadPermissoes();
			}, error => {
				this.toastsService.toastError("Ocorreu um erro ao processar a solicitação, tente novamente mais tarde.");
			});
		}

	}

	verificaAtivoInativo(actions, method) {
		var action = actions.filter(action => {
			return action.nome == method;
		});

		var grupoDeAcesso = action[0].gruposAcesso.filter(grupo => {
			return grupo.codGrupoAcesso == this.selected.codGrupoAcesso;
		});

		return grupoDeAcesso.length > 0 ? true : false;
	}

	existeAction(actions, method) {
		var existe = actions.filter(action => {
			return action.nome == method;
		});

		return existe.length > 0 ? true : false;
	}
}
