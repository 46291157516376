import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonDataService } from './../../common-data.service';
import { ModalService } from '../../shared/_services/index';
import { ToastsService } from './../../api/toasts/toasts.service';
import { AgendamentosService } from './../../api/services/agendamentos.service';
import { AtividadesService } from './../../api/services/atividades.service';
import { RefeicoesService } from './../../api/services/refeicoes.service';
import { ReservaHospedesService } from './../../api/services/reserva-hospedes.service';
declare var swal: any;

@Component({
	selector: 'app-agendamentos',
	templateUrl: './agendamentos.component.html',
	styleUrls: ['./agendamentos.component.scss']
})
export class AgendamentosComponent implements OnInit {
	isFormLoading: boolean = false;
	tableSettings: any = {
		columns: [
			{
				name: 'Atividade/Refeição',
				prop: 'atividade_refeicao'
			},
			{
				name: 'Quando',
				prop: 'quando'
			},
			{
				name: 'Descrição',
				prop: 'descricao'
			},
			{
				name: 'Status',
				prop: 'status.status'
			},
			{
				name: 'Hóspede',
				prop: 'reservaHospede.hospede.nome'
			},
		],
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true,
		addVisivel: false
	}

	pageTitle: any = {
		title: 'Agendamentos',
		breadcumbs: [
			{
				title: 'Dashboard',
				href: '/'
			}
		],
		breadcumbActive: 'Agendamentos'
	}

	form: FormGroup;
	selected: any = {};
	isCreate: boolean = false;
	atividades: any = []
	refeicoes: any = []
	reservas: any = []

	constructor(private agendamentosService: AgendamentosService,
		private modalService: ModalService,
		private fb: FormBuilder,
		private toastsService: ToastsService,
		private atividadesService: AtividadesService,
		private refeicoesService: RefeicoesService,
		private reservaHospedesService: ReservaHospedesService) {
		this.form = this.fb.group({
			tipoAgendamento: new FormControl('atividade', [Validators.required]),
			codRefeicao: new FormControl(null),
			codAtividade: new FormControl(null),
			codReservaHospede: new FormControl('', [Validators.required]),
			codStatus: new FormControl(1)
		})
	}

	ngOnInit() {
		this.load();
		this.loadAtividades();
		this.loadRefeicoes();
		this.loadReservaHospedes();
	}

	load() {
		this.agendamentosService.get().subscribe((data: any[]) => {
			data.map((item) => {
				try {
					item.atividade_refeicao = item.refeicao ? item.refeicao.descricao : item.atividade.nome;
					item.descricao = item.refeicao ? item.refeicao.descricao + " em " + item.refeicao.local.nome : item.atividade.descricao;
					item.quando = new Date(item.dataHoraAgendamento).toLocaleDateString() + " " + new Date(item.dataHoraAgendamento).toLocaleTimeString()
					return item;
				}
				catch { }
			})

			this.tableSettings.temp = data;
			this.tableSettings.filterrows = data;
			this.tableSettings.isLoading = false;
			this.isFormLoading = false;
		});
	}

	loadAtividades() {
		this.atividadesService.get().subscribe((data: any[]) => {
			this.atividades = data;
		});
	}

	loadRefeicoes() {
		this.refeicoesService.get().subscribe((data: any[]) => {
			this.refeicoes = data;
		});
	}

	loadReservaHospedes() {
		this.reservaHospedesService.get('').subscribe((data: any[]) => {
			this.reservas = data;
		});
	}

	addNew() {
		this.isCreate = true;
		this.form.reset();
		this.selected = {}
		this.modalService.open('areaModal');
	}

	onSelect(item) {
		// this.isCreate = false;
		// this.selected = item;
		// this.form.reset();
		// this.form.patchValue(item);
		// this.modalService.open('areaModal');
	}

	onReload() {
		this.tableSettings.isLoading = true;
		this.load();
	}

	updateFilter(val) {
		const temp = this.tableSettings.temp.filter(function (d) {
			return (d.nome.toLowerCase().indexOf(val) !== -1 ||
				d.descricao.toLowerCase().indexOf(val) !== -1 ||
				String(d.codigo).indexOf(val) !== -1)
				|| !val;
		});

		this.tableSettings.filterrows = temp;
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	submit() {
		console.log(this.form.value)
		// if(this.form.valid) {
		// 	this.isFormLoading = true;
		// 	if(this.isCreate) {
		// 		this.agendamentosService.post(this.form.value).subscribe((data: any) => {
		// 			this.closeModal('areaModal');
		// 			this.toastsService.toastSuccess("Salvo com sucesso!");
		// 			this.load();
		// 		}, error => {
		// 			this.isFormLoading = false;
		// 			this.toastsService.toastError("Revise todos os dados e tente novamente!");
		// 		});
		// 	}
		// 	else {
		// 		this.agendamentosService.patch(this.form.value, this.selected.codigo).subscribe(() => {
		// 			this.closeModal('areaModal');
		// 			this.toastsService.toastSuccess("Editado com sucesso!");
		// 			this.load();
		// 		}, error => {
		// 			this.isFormLoading = false;
		// 			this.toastsService.toastError("Revise todos os dados e tente novamente!");
		// 		});
		// 	}

		// }
		// else {
		// 	this.validateAllFormFields(this.form);
		// }
	}

	deleteArea() {
		swal({
			text: 'Deseja realmente deletar essa Área?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Não!',
			confirmButtonText: 'Sim, deletar!'
		}).then((result) => {
			if (result.value) {
				this.isFormLoading = true;
				this.agendamentosService.delete(this.selected.codigo).subscribe(() => {
					this.closeModal('areaModal');
					this.toastsService.toastSuccess("Deletado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					this.toastsService.toastError("Tente novamente!");
				});
			}
		});
	}

	campoValido(nomeCampo) {
		return !this.form.get(nomeCampo).valid && (this.form.get(nomeCampo).dirty || this.form.get(nomeCampo).touched)
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}
}
