import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonDataService } from './../../common-data.service';
import { LocalService } from './../../api/services/local.service';
import { ReservasService } from './../../api/services/reservas.service';
import { UserService } from './../../api/services/user.service';
import { ModalService } from '../../shared/_services/index';
import { ToastsService } from './../../api/toasts/toasts.service';
import { UnidadesDeHabitacaoService } from './../../api/services/unidades-de-habitacao.service';
import { ReservaHospedesService } from './../../api/services/reserva-hospedes.service';
import { HospedesService } from './../../api/services/hospedes.service';
declare var swal: any;

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.scss']
})
export class ReservasComponent implements OnInit {
	isFormLoading: boolean = false;
	tableSettings: any = {
		columns: [
			{ 
				name: 'Número da resreva',
				prop: 'noBitz'
			},
			{ 
				name: 'Check in',
				prop: 'strDtCheckIn'
			},
			{ 
				name: 'Check out',
				prop: 'strDtCheckOut'
			},
			{ 
				name: 'Unidade Habitação',
				prop: 'unidadeHabitacao.nome'
			}
		],
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true
	}

	pageTitle: any = {
		title: 'Reservas',
		breadcumbs: [
			{
				title: 'Dashboard',
				href: '/'
			}
		],
		breadcumbActive: 'Reservas'
	}

	form: FormGroup;
	formHospedes: FormGroup;
	selected: any = {};
	isCreate: boolean = false;

	areas: any = []
	usuarios: any = []
	unidades: any = []
	hospedes: any = []

	tableSettingsCad: any = {
		reorderable: true,
		filterrows: []
	}

	constructor(private _commondata: CommonDataService,
				private service: ReservasService,
				private userService: UserService,
				private localService: LocalService,
				private modalService: ModalService,
				private unidadesService: UnidadesDeHabitacaoService,
				private reservaHospedesService: ReservaHospedesService,
				private hospedesService: HospedesService,
				private fb: FormBuilder,
				private toastsService: ToastsService)
	{
		this.form = this.fb.group({  
			noBitz: new FormControl('', [Validators.required]),
			residente: new FormControl('', [Validators.required]),
			checkIn: new FormControl('', [Validators.required]),
			checkOut: new FormControl('', [Validators.required]),
			dtInicioReserva: new FormControl('', [Validators.required]),
			dtFimReserva: new FormControl('', [Validators.required]),
			codUnidade: new FormControl(null),
		});

		this.formHospedes = this.fb.group({  
			codHospede: new FormControl(null, [Validators.required])
		});
	}

	ngOnInit() {
		this.load();
		this.localService.getAreas().subscribe((data: any[]) => {
			this.areas = data;
		});

		this.userService.get().subscribe((data: any[]) => {
			this.usuarios = data;
		});

		this.unidadesService.get().subscribe((data: any[]) => {
			this.unidades = data;
		});

		this.hospedesService.get().subscribe((data: any[]) => {
			this.hospedes = data;
		});
	}

	load() {
		this.service.get().subscribe((data: any[]) => {
			this.tableSettings.temp = data;
			this.tableSettings.filterrows = data;
			this.tableSettings.isLoading = false;
			this.isFormLoading = false;
		});
	}

	addNew() {
		this.isCreate = true;
		this.form.reset();
		this.form.patchValue({residente: false});
		this.selected = {}
		this.tableSettingsCad.filterrows = [];
		this.modalService.open('modal');
	}

	reverseDate(str) {
		var splitString = str.split("/");
		var reverseArray = splitString.reverse();
		var joinArray = reverseArray.join("-");
		return joinArray;
	}

	onSelect(item) {
		this.isCreate = false;
		this.tableSettingsCad.filterrows = [];
		this.selected = item;
		this.form.reset();
		this.form.patchValue(item);
		this.form.patchValue(
			{
				dtInicioReserva: this.reverseDate(item.strDtInicioReserva),
				dtFimReserva: this.reverseDate(item.strDtFimReserva)
			}
		);
		this.modalService.open('modal');
		this.reservaHospedesService.get(item.codigo).subscribe((data: any[]) => {
			this.tableSettingsCad.filterrows = data;
		});
	}

	onReload() {
		this.tableSettings.isLoading = true;
		this.load();
	}

	updateFilter(val) {
		const temp = this.tableSettings.temp.filter(function (d) {
			return (d.strDtCheckIn.toLowerCase().indexOf(val) !== -1 ||
				d.strDtCheckOut.toLowerCase().indexOf(val) !== -1 ||
				d.strDtFimReserva.toLowerCase().indexOf(val) !== -1 ||
				d.strDtInicioReserva.toLowerCase().indexOf(val) !== -1 ||
				String(d.codigo).indexOf(val) !== -1)
			|| !val;
		});

		this.tableSettings.filterrows = temp;
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	submit() {
		if(this.form.valid) {
			this.isFormLoading = true;
			var body = this.form.value;
			body.hospedes = this.tableSettingsCad.filterrows;
			body.checkIn = this.form.get('checkIn').value.split('T').join(' ').replace('-', '/');
			body.checkOut = this.form.get('checkOut').value.split('T').join(' ').replace('-', '/');
			body.dtInicioReserva = this.form.get('dtInicioReserva').value.split('T').join(' ').replace('-', '/');
			body.dtFimReserva = this.form.get('dtFimReserva').value.split('T').join(' ').replace('-', '/');

			if(this.isCreate) {
				body.hospedes.map(hospede => {
					hospede.noBitz = body.noBitz;
					delete hospede.hospede;
				});
				this.service.post(body).subscribe((data: any) => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Salvo com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
			else {
				body.hospedes.map(hospede => {
					delete hospede.hospede;
				});
				this.service.patch(body, this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Editado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}

		}
		else {
			this.validateAllFormFields(this.form);
		}
	}

	remove() {
		swal({
			text: 'Deseja realmente deletar essa Reserva?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Não!',
			confirmButtonText: 'Sim, deletar!'
		}).then((result) => {
			if (result.value) {
				this.isFormLoading = true;
				this.service.delete(this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Deletado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					this.toastsService.toastError("Tente novamente!");
				});
			}
		});
	}

	campoValidoByForm(nomeCampo, formName) {
		return this.campoValido(nomeCampo, this[formName]);
	}

	campoValido(nomeCampo, form) {
		return !form.get(nomeCampo).valid && (form.get(nomeCampo).dirty || form.get(nomeCampo).touched)
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => { 
			const control = formGroup.get(field);            
			if (control instanceof FormControl) {            
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {       
				this.validateAllFormFields(control);           
			}
		});
	}

	adicionaHospede() {
		if(!this.formHospedes.valid) {
			return;
		};

		if(this.tableSettingsCad.filterrows.filter(t => t.codHospede == this.formHospedes.value.codHospede)[0] != undefined) {
			this.toastsService.toastWarning("Hóspede já adicionado!");
			return;
		};

		var data = this.hospedes.filter(t => t.codigo == this.formHospedes.value.codHospede)[0];
		var hospedeObjeto = [{
			codHospede: data.codigo,
			hospede: data,
			noBitz: this.formHospedes.value.codHospede.noBitz,
			codReserva: this.selected.codigo,
			principal: false
		}];

		this.tableSettingsCad.filterrows = [...this.tableSettingsCad.filterrows, ...hospedeObjeto];
		this.formHospedes.reset();
	}

	removerHospede(item) {
		this.tableSettingsCad.filterrows = this.tableSettingsCad.filterrows.filter(t => t.codHospede != item.codHospede);
	}

	verificaHospedePrincipal(item) {
		item.principal = !item.principal;
		var hospedes = this.tableSettingsCad.filterrows.filter(t => t.codHospede != item.codHospede);
		hospedes.map(hospede => hospede.principal = false);
	}
}
