import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
	@Input() settings: any;

	@Output() selected = new EventEmitter<any>();
	@Output() filter = new EventEmitter<string>();
	@Output() reload = new EventEmitter<any>();
	@Output() new = new EventEmitter<any>();

	constructor() { }

	ngOnInit() {
		if(this.settings.addVisivel == undefined) {
			this.settings.addVisivel = true;
		}
	}

	addNew() {
		this.new.emit();
	}

	onReload() {
		this.reload.emit();
	}

	onSelect(event) {
	    if(event.type == 'click') {
	        this.selected.emit(event.row);
	    }
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		this.filter.emit(val);
	}
}
