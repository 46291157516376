import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChecklistService } from './../../api/services/checklist.service';
import { TarefasService } from './../../api/services/tarefas.service';
import { LocalService } from './../../api/services/local.service';
import { ModalService } from '../../shared/_services/index';
import { ToastsService } from './../../api/toasts/toasts.service';
declare var swal: any;

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})

export class ChecklistComponent implements OnInit {
	isFormLoading: boolean = false;
	cpf_cnpj: any = '';
	tableSettings: any = {
		columns: [
			// { 
			// 	name: 'Código',
			// 	prop: 'codigo'
			// },
			{ 
				name: 'Nome',
				prop: 'nome'
			}
		],
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true
	}

	tableSettingsCad: any = {
		temp: [],
		reorderable: true,
		filterrows: []
	}

	pageTitle: any = {
		title: 'Checklist',
		breadcumbs: [
			{
				title: 'Dashboard',
				href: '/'
			}
		],
		breadcumbActive: 'Checklist'
	}

	form: FormGroup;
	checkListForm: FormGroup;
	selected: any = {};
	isCreate: boolean = false;

	tarefas: any = []
	areas: any = []

	constructor(private service: ChecklistService,
				private tarefasService: TarefasService,
				private modalService: ModalService,
				private fb: FormBuilder,
				private toastsService: ToastsService,
				private localService: LocalService)
	{
		this.form = this.fb.group({  
			codTarefa: new FormControl('', [Validators.required]),
		});

		this.checkListForm = this.fb.group({
			nome: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)])
		});
	}

	ngOnInit() {
		this.load();

		this.tarefasService.get().subscribe((data: any[]) => {
			this.tarefas = data;
		});

		this.localService.getAreas().subscribe((data: any[]) => {
			this.areas = data;
		});
	}

	load() {
		this.service.get().subscribe((data: any[]) => {
			this.tableSettings.temp = data;
			this.tableSettings.filterrows = data;
			this.tableSettings.isLoading = false;
			this.isFormLoading = false;
		});
	}

	loadTarefas(item) {
		this.service.getAll(item.codigo).subscribe((data: any[]) => {
			this.checkListForm.patchValue(item);
			this.tableSettingsCad.filterrows = data['tarefas'];
			this.isFormLoading = false;
			
		});
	}

	addNew() {
		this.isCreate = true;
		this.form.reset();
		this.checkListForm.reset();
		this.tableSettingsCad.filterrows = [];
		this.selected = {}
		this.modalService.open('modal');
	}

	onSelect(event) {
		if(event.type == 'click') {
			this.tableSettingsCad.filterrows = []
			this.isCreate = false;
			this.selected = event.row;
			this.form.reset();
			this.form.patchValue(event.row);
			this.checkListForm.reset();
			this.loadTarefas(event.row);
			this.modalService.open('modal');
		}
	}

	onReload() {
		this.tableSettings.isLoading = true;
		this.load();
	}

	updateFilter(val) {
		const temp = this.tableSettings.temp.filter(function (d) {
			return (d.nome.toLowerCase().indexOf(val) !== -1 ||
				String(d.codigo).indexOf(val) !== -1 ) 
			|| !val;
		});

		this.tableSettings.filterrows = temp;
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	submit() {
		if(this.checkListForm.valid) {
			this.isFormLoading = true;
			var tarefas = this.tableSettingsCad.filterrows;

			tarefas.map(tarefa => {
				tarefa.codChecklist = this.selected.codigo
			})
			var body = {
				nome: this.checkListForm.value.nome,
				checkListTarefas: tarefas
			};

			if(this.isCreate) {
				this.service.post(body).subscribe((data: any) => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Salvo com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
			else {
				this.service.patch(body, this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Editado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
		}
		else {
			this.validateAllFormFields(this.form);
			this.validateAllFormFields(this.checkListForm);
		}
	}

	compareFunction(item, selected) {
		return item.codTarefa === selected.codTarefa
	}

	campoValidoByForm(nomeCampo, formName) {
		return this.campoValido(nomeCampo, this[formName]);
	}

	campoValido(nomeCampo, form) {
		return !form.get(nomeCampo).valid && (form.get(nomeCampo).dirty || form.get(nomeCampo).touched)
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => { 
			const control = formGroup.get(field);            
			if (control instanceof FormControl) {            
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {  
				console.log(control)     
				this.validateAllFormFields(control);           
			}
		});
	}

	remove() {
		swal({
			text: 'Deseja realmente deletar esse checklist?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Não!',
			confirmButtonText: 'Sim, deletar!'
		}).then((result) => {
			if (result.value) {
				this.isFormLoading = true;
				this.service.delete(this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Deletado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					this.toastsService.toastError("Tente novamente!");
				});
			}
		});
	}

	adicionaTarefa() {
		if(!this.form.valid) {
			return;
		};

		if(this.tableSettingsCad.filterrows.filter(t => t.codTarefa == this.form.value.codTarefa)[0] != undefined) {
			this.toastsService.toastWarning("Tarefa já adicionada!");
			return
		};

		var data = this.tarefas.filter(t => t.codTarefa == this.form.value.codTarefa)[0];

		this.tableSettingsCad.filterrows = [...this.tableSettingsCad.filterrows, ...data];
		this.form.reset();
	}

	removerTarefa(item) {
		this.tableSettingsCad.filterrows = this.tableSettingsCad.filterrows.filter(t => t.codTarefa != item.codTarefa);
	}

	calculaTempoTotal(tarefas) {
		return this.sumTime(tarefas.map(tarefa => tarefa.tempoExecucao))
	}

	sumTime(array = []) {
	    var times = [3600, 60, 1],
	        sum = array
	            .map(s => s.split(':').reduce((s, v, i) => s + times[i] * v, 0))
	            .reduce((a, b) => a + b, 0);

	    return times
	        .map(t => [Math.floor(sum / t), sum %= t][0])
	        .map(v => v.toString().padStart(2, '0'))
	        .join(':');
	}
}
