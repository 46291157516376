import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})

export class CargosService {

	constructor(private http: HttpClient) { }

	private authHeader(): string {
		return `Bearer ${JSON.parse(localStorage.getItem('User')).token}`;
	}

	public get() {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.get(`${environment.apiUrl}/cadastro/cargosfuncionario`, { headers });
	}

	// public post(hospede) {
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append('Authorization', this.authHeader());

	// 	return this.http.post(`${environment.apiUrl}/cadastro/cargosfuncionario`, hospede, { headers });
	// }

	// public patch(hospede, id) {
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append('Authorization', this.authHeader());

	// 	return this.http.patch(`${environment.apiUrl}/cadastro/cargosfuncionario/${id}`, hospede, { headers });
	// }

	// public delete(id) {
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append('Authorization', this.authHeader());

	// 	return this.http.delete(`${environment.apiUrl}/cadastro/cargosfuncionario/${id}`, { headers });
	// }
}
