import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';

import { ModalService } from '../_services/index';

@Component({
    selector: 'app-model',
    template: '<ng-content></ng-content>'
})

export class Modal1Component implements OnInit, OnDestroy {
    @Input() id: string;
    private element: JQuery;

    constructor(private modalService: ModalService, private el: ElementRef) {
        this.element = $(el.nativeElement);
    }

    ngOnInit(): void {
        let modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        this.element.appendTo('body');

        // close modal on background click
        this.element.on('click', (e: any) => {
            var target = $(e.target);

            if (!target.closest('.modal-dialog').length && 
                !(target.attr("class") && (target.attr("class").includes('btn-light') ||
                                           target.attr("class").includes('modal') ||
                                           target.attr("class").includes('fa-trash') ||
                                           target.attr("class").includes('ng-option ng-option-marked') || 
                                           target.attr("class").includes('ng-option ng-option-selected ng-option-marked') ||
                                           target.attr("class").includes('ng-option-label') ||
                                           target.attr("class").includes('ng-value-icon') || 
                                           target.attr("class").includes('ng-arrow-wrapper')))) {
                modal.close();
            }
        }).keyup((e) => {
          if (e.keyCode == 27) {
              modal.close();
          }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.element.removeClass('hide');
        this.element.addClass('show');
        $('body').addClass('modal-open');
        this.element.show();
        setTimeout(() => {
            this.element.children().addClass('modal-dialog-show');
            this.element.focus();
        }, 100);
    }

    // close modal
    close(): void {
        this.element.children().removeClass('modal-dialog-show');
        this.element.removeClass('show');
        this.element.addClass('hide');
        $('body').removeClass('modal-open');
        setTimeout(() => {
            this.element.hide();
        }, 400);
    }
}