import { Component, OnInit } from '@angular/core';
import { RelatorioReservasService } from './../../api/services/relatorio-reservas.service';
import { FaturaService } from './../../api/services/fatura.service';
import { ModalService } from '../../shared/_services/index';
import { ToastsService } from './../../api/toasts/toasts.service';
declare var swal: any;


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
	isLoadingFatura: boolean = false;
	tableSettings: any = {
		columns: [
			{ 
				name: 'Nome',
				prop: 'nomeHospede'
			},
			{ 
				name: 'Fatura',
				prop: 'statusFatura'
			},
			{ 
				name: 'Diárias',
				prop: 'diariasReserva'
			},
			{ 
				name: 'CheckIn',
				prop: 'strDtCheckIn'
			},
			{ 
				name: 'CheckOut',
				prop: 'strDtCheckout'
			},
			{ 
				name: 'Valor(R$)',
				prop: 'valorFatura'
			}
		],
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true,
		addVisivel: false
	}

	pageTitle: any = {
		title: 'Checkout',
		breadcumbs: [
		{
			title: 'Dashboard',
			href: '/'
		}
		],
		breadcumbActive: 'Checkout'
	}

	selected: any = {};
	isCreate: boolean = false;
	faturas: any = []
	itensFatura: any[]

	constructor(private service: RelatorioReservasService,
				private faturaService: FaturaService,
				private modalService: ModalService,
				private toastsService: ToastsService)
	{
	}

	ngOnInit() {
		this.load();
	}

	load() {
		this.service.get().subscribe((data: any[]) => {
			this.tableSettings.temp = data;
			this.tableSettings.filterrows = data;
			this.tableSettings.isLoading = false;
		});
	}

	onSelect(item) {
		this.faturas = [];
		this.isCreate = false;
		this.selected = item;
		this.isLoadingFatura = true;
		this.loadFatura();
		this.modalService.open('modal');
	}

	loadFatura() {
		this.faturaService.get(this.selected.codigo).subscribe((data: any[]) => {
			this.faturas = data;
			this.isLoadingFatura = false;
		});
	}

	onReload() {
		this.tableSettings.isLoading = true;
		this.load();
	}

	updateFilter(val) {
		const temp = this.tableSettings.temp.filter(function (d) {
			return (d.nomeHospede.toLowerCase().indexOf(val) !== -1 || 
				d.statusFatura.toLowerCase().indexOf(val) !== -1 ||
				String(d.codigo).indexOf(val) !== -1 ) 
			|| !val;
		});

		this.tableSettings.filterrows = temp;
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	campoValidoByForm(nomeCampo, formName) {
		return this.campoValido(nomeCampo, this[formName]);
	}

	campoValido(nomeCampo, form) {
		return !form.get(nomeCampo).valid && (form.get(nomeCampo).dirty || form.get(nomeCampo).touched)
	}

	somaTotal(despesas) {
		return Math.round(despesas.map(d => d.valor).reduce((sum, current) => sum + current)*100)/100;
	}

	fecharFatura(fatura) {
		swal({
			text: 'Deseja realmente fechar essa fatura?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#28a745',
			cancelButtonColor: '#d33',
			cancelButtonText: 'Não!',
			confirmButtonText: 'Sim, fechar fatura!'
		}).then((result) => {
			if (result.value) {
				this.isLoadingFatura = true;
				this.faturaService.patch({codStatus: 2}, fatura.codigo).subscribe(() => {
					this.toastsService.toastSuccess("Fatura fechada com sucesso!");
					this.loadFatura(); //Atualiza registro de fatura
					this.load(); //Atualiza Tabela de listagem de reservas
				}, error => {
					this.toastsService.toastError("Tente novamente!");
				});
			}
		});
	}

	totalItemsGeral() {
		if(this.faturas.length == 0)
			return 0;

		var total = 0;
		this.faturas.map(d => total += d.despesas.length);

		return total;
	}

	visualizarItensFatura(selected) {
		this.itensFatura = selected.itensFatura;
		this.modalService.open('modalItensFatura');
	}

	currencyFormat(value) {
		return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value)
	}
}
