import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonDataService } from './../../common-data.service';
import { FuncionariosService } from './../../api/services/funcionarios.service';
import { RotinasService } from './../../api/services/rotinas.service';
import { TarefasService } from './../../api/services/tarefas.service';
import { ProgramacaoService } from './../../api/services/programacao.service';
import { ChecklistService } from './../../api/services/checklist.service';
import { ModalService } from '../../shared/_services/index';
import { ToastsService } from './../../api/toasts/toasts.service';
import * as moment from 'moment';
declare var swal: any;

@Component({
	selector: 'app-programacao',
	templateUrl: './programacao.component.html',
	styleUrls: ['./programacao.component.scss']
})
export class ProgramacaoComponent implements OnInit {
	isFormLoading: boolean = false;
	totalTime: string;
	nomeFuncionarioEdicao: string;
	tableSettings: any = {
		columns: [
			{
				name: 'Nome',
				prop: 'nome'
			},
			{
				name: 'Cargo',
				prop: 'cargo.nome'
			},
			{
				name: 'Função',
				prop: 'funcao'
			},
			{
				name: 'Departamento',
				prop: 'departamento.descricao'
			}
		],
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true,
		addVisivel: false
	}

	tableSettingsCad: any = {
		reorderable: true,
		filterrows: []
	}

	pageTitle: any = {
		title: 'Programação',
		breadcumbs: [
			{
				title: 'Dashboard',
				href: '/'
			}
		],
		breadcumbActive: 'Programação'
	}

	horarios: any = [
		{ hora: "07:00" }, { hora: "07:30" },
		{ hora: "08:00" }, { hora: "08:30" },
		{ hora: "09:00" }, { hora: "09:30" },
		{ hora: "10:00" }, { hora: "10:30" },
		{ hora: "11:00" }, { hora: "11:30" },
		{ hora: "12:00" }, { hora: "12:30" },
		{ hora: "13:00" }, { hora: "13:30" },
		{ hora: "14:00" }, { hora: "14:30" },
		{ hora: "15:00" }, { hora: "15:30" },
		{ hora: "16:00" }, { hora: "16:30" },
		{ hora: "17:00" }, { hora: "17:30" },
		{ hora: "18:00" }, { hora: "18:30" },
		{ hora: "19:00" }, { hora: "19:30" },
		{ hora: "20:00" }, { hora: "20:30" },
		{ hora: "21:00" }, { hora: "21:30" },
		{ hora: "22:00" }, { hora: "22:30" },
		{ hora: "23:00" }, { hora: "23:30" },
		{ hora: "00:00" }, { hora: "00:30" },
		{ hora: "01:00" }, { hora: "01:30" },
		{ hora: "02:00" }, { hora: "02:30" },
		{ hora: "03:00" }, { hora: "03:30" },
		{ hora: "04:00" }, { hora: "04:30" },
		{ hora: "05:00" }, { hora: "05:30" },
		{ hora: "06:00" }, { hora: "06:30" }
	];

	tipoRotina: string = "Checklist";

	programacaoForm: FormGroup;
	selected: any = {};

	tarefas: any = []
	checklists: any = []

	constructor(private _commondata: CommonDataService,
		private service: FuncionariosService,
		private checklistService: ChecklistService,
		private tarefasService: TarefasService,
		private programacaoService: ProgramacaoService,
		private rotinas: RotinasService,
		private modalService: ModalService,
		private fb: FormBuilder,
		private toastsService: ToastsService) {
		this.programacaoForm = this.fb.group({
			codTarefa: new FormControl(null),
			codChecklist: new FormControl(null),
			tipoRotina: new FormControl('checklist', [Validators.required]),
			horario: new FormControl(null, [Validators.required]),
			recorrencia: new FormControl(false),
			tipoRecorrencia: new FormControl(''),
			recorrenciaPeriodica: this.fb.group({
				dataInicio: new FormControl(''),
				dataFim: new FormControl('')
			}),
			programacao: this.fb.group({
				codFuncionario: new FormControl('')
			}),
			recorrenciaSemanal: this.fb.group({
				segunda: new FormControl(false),
				terca: new FormControl(false),
				quarta: new FormControl(false),
				quinta: new FormControl(false),
				sexta: new FormControl(false),
				sabado: new FormControl(false),
				domingo: new FormControl(false)
			})
		});
	}

	ngOnInit() {
		this.load();

		this.tarefasService.get().subscribe((data: any[]) => {
			this.tarefas = data;
		});

		this.checklistService.get().subscribe((data: any[]) => {
			this.checklists = data;
		});
	}

	load() {
		this.service.get().subscribe((data: any[]) => {
			this.tableSettings.temp = data;
			this.tableSettings.filterrows = data;
			this.tableSettings.isLoading = false;
			this.isFormLoading = false;
		});
	}

	addNew() {
		// this.form.reset();
		this.selected = {}
		this.modalService.open('modal');
	}

	onSelect(item) {
		this.nomeFuncionarioEdicao = item.nome;
		this.isFormLoading = true;
		this.selected = item;
		this.programacaoForm.reset();
		this.programacaoForm.patchValue({
			recorrenciaSemanal: {
				segunda: false,
				terca: false,
				quarta: false,
				quinta: false,
				sexta: false,
				sabado: false,
				domingo: false
			}
		});
		this.modalService.open('modal');
		this.programacaoService.get(this.selected.codigo).subscribe((data: any[]) => {

			/*CALCULA O TEMPO TOTAL DE DURAÇÃO DAS TAREFAS E CHECKLIST ADICIONADOS*/
			let sumTime = moment.duration('00:00:00')
			data.map((item) => {
				sumTime = sumTime.add(moment.duration(item.horarioDuracao))
			})
			this.totalTime = moment.utc(sumTime.as('milliseconds')).format("HH:mm:ss")
			/*-----------------------------------------------------------------------------------*/

			this.tableSettingsCad.filterrows = data;
			this.isFormLoading = false;
		});
	}

	onReload() {
		this.tableSettings.isLoading = true;
		this.load();
	}

	updateFilter(val) {
		const temp = this.tableSettings.temp.filter(function (d) {
			return (d.nome.toLowerCase().indexOf(val) !== -1 ||
				d.cargo.nome.toLowerCase().indexOf(val) !== -1 ||
				d.telefone.toLowerCase().indexOf(val) !== -1 ||
				d.usuario.email.toLowerCase().indexOf(val) !== -1 ||
				d.departamento.descricao.toLowerCase().indexOf(val) !== -1 ||
				String(d.codigo).indexOf(val) !== -1)
				|| !val;
		});

		this.tableSettings.filterrows = temp;
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	submit() {
		if (this.programacaoForm.valid) {
			//Se houver conflitos de horário, não cadastra, e apresenta mensagem para o usuário
			if (this.tableSettingsCad.filterrows.filter(F => F.horarioExecucao.indexOf(this.programacaoForm.value.horario) != -1).length > 0) {
				this.toastsService.toastError("Não é possível adicionar novo registro à programação que conflite em horário com um já cadastrado. Altere o horário e insira novamente!");
			}
			else {
				this.isFormLoading = true;
				var body = this.programacaoForm.value;
				body.programacao.codFuncionario = this.selected.codigo;

				if (this.programacaoForm.value.recorrencia) {
					if (this.programacaoForm.get('tipoRecorrencia').value == 'semanal') {
						var date = new Date();
						body.recorrenciaPeriodica.dataFim = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
						body.recorrenciaPeriodica.dataInicio = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
					}
					else {
						body.recorrenciaPeriodica.dataFim = this.programacaoForm['controls']['recorrenciaPeriodica']['controls'].dataFim.value.split('T').join(' ').replace('-', '/');
						body.recorrenciaPeriodica.dataInicio = this.programacaoForm['controls']['recorrenciaPeriodica']['controls'].dataInicio.value.split('T').join(' ').replace('-', '/');
					};

					body.recorrenciaPeriodica.periodo = this.programacaoForm.get('tipoRecorrencia').value;
				}

				this.rotinas.post(body).subscribe((data: any) => {
					this.programacaoForm.reset();
					this.programacaoForm.patchValue({
						recorrenciaSemanal: {
							segunda: false,
							terca: false,
							quarta: false,
							quinta: false,
							sexta: false,
							sabado: false,
							domingo: false
						}
					});
					this.programacaoService.get(this.selected.codigo).subscribe((data: any[]) => {
						/*CALCULA O TEMPO TOTAL DE DURAÇÃO DAS TAREFAS E CHECKLIST ADICIONADOS*/
						let sumTime = moment.duration('00:00:00')
						data.map((item) => {
							sumTime = sumTime.add(moment.duration(item.horarioDuracao))
						})
						this.totalTime = moment.utc(sumTime.as('milliseconds')).format("HH:mm:ss")
						/*-----------------------------------------------------------------------------------*/

						this.tableSettingsCad.filterrows = data;
						this.isFormLoading = false;
						this.toastsService.toastSuccess("Salvo com sucesso!");
					});
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
		}
		else {
			this.validateAllFormFields(this.programacaoForm);
		}
	}

	// remove() {
	// 	swal({
	// 		text: 'Deseja realmente deletar essa rotina?',
	// 		type: 'warning',
	// 		showCancelButton: true,
	// 		confirmButtonColor: '#d33',
	// 		cancelButtonColor: '#3085d6',
	// 		cancelButtonText: 'Não!',
	// 		confirmButtonText: 'Sim, deletar!'
	// 	}).then((result) => {
	// 		if (result.value) {
	// 			this.isFormLoading = true;
	// 			this.service.delete(this.selected.codigo).subscribe(() => {
	// 				this.closeModal('modal');
	// 				this.toastsService.toastSuccess("Deletado com sucesso!");
	// 				this.load();
	// 			}, error => {
	// 				this.isFormLoading = false;
	// 				this.toastsService.toastError("Tente novamente!");
	// 			});
	// 		}
	// 	});
	// }

	campoValidoByForm(nomeCampo, formName) {
		return this.campoValido(nomeCampo, this[formName]);
	}

	campoValido(nomeCampo, form) {
		return !form.get(nomeCampo).valid && (form.get(nomeCampo).dirty || form.get(nomeCampo).touched)
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}

	remover(item) {
		swal({
			text: 'Deseja realmente deletar essa programação?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Não!',
			confirmButtonText: 'Sim, deletar!'
		}).then((result) => {
			if (result.value) {
				this.isFormLoading = true;
				this.programacaoService.delete(item.codProgramacao).subscribe(() => {
					this.tableSettingsCad.filterrows = this.tableSettingsCad.filterrows.filter(t => t.codProgramacao != item.codProgramacao);

					/*CALCULA O TEMPO TOTAL DE DURAÇÃO DAS TAREFAS E CHECKLIST ADICIONADOS*/
					let sumTime = moment.duration('00:00:00')
					this.tableSettingsCad.filterrows.map((item) => {
						sumTime = sumTime.add(moment.duration(item.horarioDuracao))
					})
					this.totalTime = moment.utc(sumTime.as('milliseconds')).format("HH:mm:ss")
					/*-----------------------------------------------------------------------------------*/

					this.toastsService.toastSuccess("Deletado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					this.toastsService.toastError("Tente novamente!");
				});
			}
		});
	}
}
