import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonDataService } from './../../common-data.service';
import { CategoriaDeAtividadesService } from './../../api/services/categoria-de-atividades.service';
import { AtividadesService } from './../../api/services/atividades.service';
import { ModalService } from '../../shared/_services/index';
import { ToastsService } from './../../api/toasts/toasts.service';
declare var swal: any;
import * as moment from 'moment';

@Component({
  selector: 'app-atividades',
  templateUrl: './atividades.component.html',
  styleUrls: ['./atividades.component.scss']
})
export class AtividadesComponent implements OnInit {
	isFormLoading: boolean = false;
	tableSettings: any = {
		columns: [
			// { 
			// 	name: 'Código',
			// 	prop: 'codigo'
			// },
			{ 
				name: 'Nome',
				prop: 'nome'
			},
			{ 
				name: 'Descrição',
				prop: 'descricao'
			},
			{ 
				name: 'Categoria',
				prop: 'categoriaAtividade.nome'
			},
			{ 
				name: 'Nº de vagas',
				prop: 'noVagas'
			}
		],
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true
	}

	pageTitle: any = {
		title: 'Atividades',
		breadcumbs: [
			{
				title: 'Dashboard',
				href: '/'
			}
		],
		breadcumbActive: 'Atividades'
	}

	form: FormGroup;
	selected: any = {};
	isCreate: boolean = false;

	codAtividades: any = []

	constructor(private _commondata: CommonDataService,
				private service: AtividadesService,
				private categoriService: CategoriaDeAtividadesService,
				private modalService: ModalService,
				private fb: FormBuilder,
				private toastsService: ToastsService)
	{
		this.form = this.fb.group({  
			nome: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]),
			descricao: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
			codCategoriaAtividade: new FormControl('', [Validators.required]),
			noVagas: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
			duracao: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]),
			dataHoraAtividade: new FormControl(''),
			preco: new FormControl(''),
			link: new FormControl(''),
			prazoCancelamento: new FormControl(''),
			cortesia: new FormControl(false),
			suspensa: new FormControl(false),
			permiteAgendamento: new FormControl(false),
			necessidadeAnfitriao: new FormControl(false),
			permiteAvaliacao: new FormControl(false),
			recorrente: new FormControl(false),
			permiteDesconto: new FormControl(false)
		});
	}

	ngOnInit() {
		this.load();
		this.categoriService.get().subscribe((data: any[]) => {
			this.codAtividades = data;
		});
	}

	load() {
		this.service.get().subscribe((data: any[]) => {
			this.tableSettings.temp = data;
			this.tableSettings.filterrows = data;
			this.tableSettings.isLoading = false;
			this.isFormLoading = false;
		});
	}

	addNew() {
		this.isCreate = true;
		this.form.reset();
		this.form.patchValue({
			cortesia: false,
			suspensa: false,
			permiteAgendamento: false,
			necessidadeAnfitriao: false,
			permiteAvaliacao: false,
			recorrente: false,
			permiteDesconto: false
		});

		this.selected = {}
		this.modalService.open('modal');
	}

	onSelect(item) {
		this.isCreate = false;
		this.selected = item;
		this.form.reset();
		this.form.patchValue(item);
		this.modalService.open('modal');
	}

	onReload() {
		this.tableSettings.isLoading = true;
		this.load();
	}

	updateFilter(val) {
		const temp = this.tableSettings.temp.filter(function (d) {
			return (d.nome.toLowerCase().indexOf(val) !== -1 || 
					d.descricao.toLowerCase().indexOf(val) !== -1 ||
					d.categoriaAtividade.nome.toLowerCase().indexOf(val) !== -1 ||
					d.duracao.toLowerCase().indexOf(val) !== -1 ||
					String(d.noVagas).indexOf(val) !== -1 ||
					String(d.codigo).indexOf(val) !== -1 ) || !val;
		});

		this.tableSettings.filterrows = temp;
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	submit() {
		if(this.form.valid) {
			this.isFormLoading = true;
			var body = this.form.value;
			body.dataHoraAtividade = this.form.get('dataHoraAtividade').value.split('T').join(' ').replace('-', '/');

			if(this.isCreate) {
				this.service.post(this.form.value).subscribe((data: any) => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Salvo com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
			else {
				this.service.patch(this.form.value, this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Editado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}

		}
		else {
			this.validateAllFormFields(this.form);
		}
	}

	remove() {
		swal({
			text: 'Deseja realmente deletar essa Categoria de Atividade?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Não!',
			confirmButtonText: 'Sim, deletar!'
		}).then((result) => {
			if (result.value) {
				this.isFormLoading = true;
				this.service.delete(this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Deletado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					this.toastsService.toastError("Tente novamente!");
				});
			}
		});
	}

	campoValido(nomeCampo) {
		return !this.form.get(nomeCampo).valid && (this.form.get(nomeCampo).dirty || this.form.get(nomeCampo).touched)
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => { 
			const control = formGroup.get(field);            
			if (control instanceof FormControl) {            
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {       
				this.validateAllFormFields(control);           
			}
		});
	}

	verificaNegativo() {
		if(this.form.get('prazoCancelamento').value && this.form.get('prazoCancelamento').value < 0) {
			this.form.patchValue({prazoCancelamento: 0});
		}
	}
}
