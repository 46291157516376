import { Component, OnInit } from '@angular/core';
import { UserService } from './../api/services/user.service';
import { ToastsService } from './../api/toasts/toasts.service';
import { Router } from "@angular/router";
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public email: string
	public senha: string
  public isLoading: boolean = false
  public isReset: boolean = false
  public randomImg: string = `url(assets/images/bg/bg0${Math.floor(Math.random() * (5 - 1 + 1)) + 1}.jpg)`

  constructor(
  	private userService: UserService,
    private toastsService: ToastsService, 
    private router: Router) { }

  ngOnInit() {
	}

	public logIn() {
    this.isLoading = true;
		if(this.email && this.senha) {
			this.userService.auth(this.email, this.senha).subscribe(data => {
        var jwt = jwt_decode(data['token']);
        var decoded_json = JSON.parse(jwt['unique_name']);
        this.userService.setUser({...decoded_json, ...data});
        window.location.href = '/';

			}, data => {
        this.isLoading = false;
        this.toastsService.toastError("Credenciais inválidas, verifique novamente.");
      })
		}
    else {
      this.isLoading = false;
      this.toastsService.toastError("Credenciais inválidas, verifique novamente.");
    }
	}

  public recoveryPassword() {
    this.isLoading = true;
    if(this.email) {
      const msg = 'Caso exista algum usuário com o e-mail informado, uma mensagem com a instruções para redefinir a senha será enviado!';
      this.userService.sendEmailToResetPassword(this.email).subscribe(data => {
        this.toastsService.toastSuccess(msg);
        this.isLoading = false;
        this.email = '';
      }, data => {
        this.email = '';
        this.isLoading = false;
        this.toastsService.toastError("Revise os dados e tente novamente!");
      })
    }
    else {
      this.isLoading = false;
      this.toastsService.toastError("E-mail inválido!");
    }
  }
}
