import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonDataService } from './../../common-data.service';
import { LocalService } from './../../api/services/local.service';
import { FuncionariosService } from './../../api/services/funcionarios.service';
import { DepartamentosService } from './../../api/services/departamentos.service';
import { UserService } from './../../api/services/user.service';
import { CargosService } from './../../api/services/cargos.service'
import { ModalService } from '../../shared/_services/index';
import { ToastsService } from './../../api/toasts/toasts.service';
declare var swal: any;

@Component({
	selector: 'app-funcionarios',
	templateUrl: './funcionarios.component.html',
	styleUrls: ['./funcionarios.component.scss']
})
export class FuncionariosComponent implements OnInit {
	isFormLoading: boolean = false;
	tableSettings: any = {
		columns: [
			{
				name: 'Nome',
				prop: 'nome'
			},
			{
				name: 'Função',
				prop: 'funcao'
			},
			{
				name: 'Telefone',
				prop: 'telefone'
			},
			{
				name: 'Usuário',
				prop: 'usuario.email'
			}
		],
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true
	}

	pageTitle: any = {
		title: 'Funcionários',
		breadcumbs: [
			{
				title: 'Dashboard',
				href: '/'
			}
		],
		breadcumbActive: 'Funcionários'
	}

	form: FormGroup;
	extraForm: FormGroup;
	selected: any = {};
	isCreate: boolean = false;

	usuarios: any = []
	departamentos: any = []
	cargos: any = []

	constructor(private _commondata: CommonDataService,
		private service: FuncionariosService,
		private userService: UserService,
		private departamentoService: DepartamentosService,
		private localService: LocalService,
		private modalService: ModalService,
		private fb: FormBuilder,
		private toastsService: ToastsService,
		private cargosService: CargosService,) {
		this.form = this.fb.group({
			nome: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
			codCargo: new FormControl('', [Validators.required]),
			funcao: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(2000)]),
			telefone: new FormControl('', [Validators.required]),
			codFuncionarioChefe: new FormControl(''),
			codDepartamento: new FormControl('', [Validators.required])
		});

		this.extraForm = this.fb.group({
			email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.minLength(8)]),
			senha: new FormControl('', [Validators.required, Validators.pattern('(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}'), Validators.minLength(8)]),
		})
	}

	ngOnInit() {
		this.load();

		this.userService.get().subscribe((data: any[]) => {
			this.usuarios = data;
		});

		this.departamentoService.get().subscribe((data: any[]) => {
			this.departamentos = data;
		});

		this.cargosService.get().subscribe((data: any[]) => {
			this.cargos = data;
		});
	}

	load() {
		this.service.get().subscribe((data: any[]) => {
			this.tableSettings.temp = data;
			this.tableSettings.filterrows = data;
			this.tableSettings.isLoading = false;
			this.isFormLoading = false;
		});
	}

	addNew() {
		this.isCreate = true;
		this.form.reset();
		this.selected = {}
		this.modalService.open('modal');
	}

	onSelect(item) {
		this.isCreate = false;
		this.selected = item;
		this.form.reset();
		this.form.patchValue(item);
		this.extraForm.reset();
		this.extraForm.patchValue(item.usuario);
		this.modalService.open('modal');
	}

	onReload() {
		this.tableSettings.isLoading = true;
		this.load();
	}

	updateFilter(val) {
		const temp = this.tableSettings.temp.filter(function (d) {
			return (d.nome.toLowerCase().indexOf(val) !== -1 ||
				d.cargo.toLowerCase().indexOf(val) !== -1 ||
				d.telefone.toLowerCase().indexOf(val) !== -1 ||
				d.usuario.email.toLowerCase().indexOf(val) !== -1 ||
				String(d.codigo).indexOf(val) !== -1)
				|| !val;
		});

		this.tableSettings.filterrows = temp;
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	submit() {
		if (this.form.valid && !this.isCreate || this.extraForm.valid && this.isCreate) {
			this.isFormLoading = true;
			if (this.isCreate) {
				let body = this.form.value;
				body.usuario = this.extraForm.value;
				this.service.post(body).subscribe((data: any) => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Salvo com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
			else {
				let body = this.form.value;
				body.usuario = this.extraForm.value;
				body.usuario.codUsuario = body.codUsuario = this.selected.usuario.codUsuario;
				this.service.patch(this.form.value, this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Editado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}

		}
		else {
			this.validateAllFormFields(this.form);
			this.validateAllFormFields(this.extraForm);
		}
	}

	remove() {
		swal({
			text: 'Deseja realmente deletar esse Funcionário?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Não!',
			confirmButtonText: 'Sim, deletar!'
		}).then((result) => {
			if (result.value) {
				this.isFormLoading = true;
				this.service.delete(this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Deletado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					this.toastsService.toastError("Tente novamente!");
				});
			}
		});
	}

	campoValido(nomeCampo) {
		return !this.form.get(nomeCampo).valid && (this.form.get(nomeCampo).dirty || this.form.get(nomeCampo).touched)
	}

	campoValidoEx(nomeCampo) {
		return !this.extraForm.get(nomeCampo).valid && (this.extraForm.get(nomeCampo).dirty || this.extraForm.get(nomeCampo).touched)
	}

	async validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}
}
