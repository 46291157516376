import { Component, ViewContainerRef } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ng8';

  constructor(vRef: ViewContainerRef, 
              private config: NgSelectConfig) {
  	this.config.notFoundText = 'Nenhum item encontrado!';
  }

  ngOnInit() {  }
}
