import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HospedesService } from './../../api/services/hospedes.service';
import { UserService } from './../../api/services/user.service';
import { ModalService } from '../../shared/_services/index';
import { ToastsService } from './../../api/toasts/toasts.service';
declare var swal: any;

@Component({
  selector: 'app-hospedes',
  templateUrl: './hospedes.component.html',
  styleUrls: ['./hospedes.component.scss']
})

export class HospedesComponent implements OnInit {
	isFormLoading: boolean = false;
	cpf_cnpj: any = '';
	tableSettings: any = {
		columns: [
			// { 
			// 	name: 'Código',
			// 	prop: 'codigo'
			// },
			{ 
				name: 'Nome',
				prop: 'nome'
			},
			{ 
				name: 'CPF/CNPJ',
				prop: 'cpF_CNPJ'
			},
			{ 
				name: 'Telefone',
				prop: 'telefone'
			},
			{ 
				name: 'Usuário',
				prop: 'usuario.email'
			}
		],
		temp: [],
		reorderable: true,
		filterrows: [],
		isLoading: true
	}

	pageTitle: any = {
		title: 'Hóspedes',
		breadcumbs: [
			{
				title: 'Dashboard',
				href: '/'
			}
		],
		breadcumbActive: 'Hóspedes'
	}

	form: FormGroup;
	extraForm: FormGroup;
	selected: any = {};
	isCreate: boolean = false;

	usuarios: any = []

	constructor(private service: HospedesService,
							private userService: UserService,
							private modalService: ModalService,
							private fb: FormBuilder,
							private toastsService: ToastsService)
	{
		this.form = this.fb.group({  
			nome: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
			dataNascimento: new FormControl('', [Validators.required]),
			cpF_CNPJ: new FormControl('', [Validators.required]),
			telefone: new FormControl('', [Validators.required]),
			observacao: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(250)])
		});

		this.extraForm = this.fb.group({
			email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.minLength(8)]),
			senha: new FormControl('', [Validators.required, Validators.pattern('(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}'), Validators.minLength(8)]),
		})
	}

	ngOnInit() {
		this.load();

		this.userService.get().subscribe((data: any[]) => {
			this.usuarios = data;
		});
	}

	load() {
		this.service.get().subscribe((data: any[]) => {
			this.tableSettings.temp = data;
			this.tableSettings.filterrows = data;
			this.tableSettings.isLoading = false;
			this.isFormLoading = false;
		});
	}

	isCPF(): boolean{
		return this.cpf_cnpj == null ? true : this.cpf_cnpj.length < 12 ? true : false;
	}

	getCpfCnpjMask(): string{
		return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
	}

	addNew() {
		this.isCreate = true;
		this.form.reset();
		this.selected = {}
		this.modalService.open('modal');
	}

	onSelect(item) {
		this.isCreate = false;
		this.selected = item;
		this.form.reset();
		this.form.patchValue(item);
		this.form.patchValue(
			{
				dataNascimento: item.dataNascimento.split('T')[0]
			}
		);
		this.modalService.open('modal');
	}

	onReload() {
		this.tableSettings.isLoading = true;
		this.load();
	}

	updateFilter(val) {
		const temp = this.tableSettings.temp.filter(function (d) {
			return (d.nome.toLowerCase().indexOf(val) !== -1 || 
				d.usuario.email.toLowerCase().indexOf(val) !== -1 ||
				d.telefone.toLowerCase().indexOf(val) !== -1 ||
				d.cpF_CNPJ.toLowerCase().indexOf(val) !== -1 ||
				String(d.codigo).indexOf(val) !== -1 ) 
			|| !val;
		});

		this.tableSettings.filterrows = temp;
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	submit() {
		if(this.form.valid && !this.isCreate || this.extraForm.valid && this.isCreate) {
			this.isFormLoading = true;
			var body = this.form.value;
			body.dataNascimento = this.form.get('dataNascimento').value.split('T').join(' ').replace('-', '/');
			if(this.isCreate) {
				debugger
				body.usuario = this.extraForm.value;
				this.service.post(body).subscribe((data: any) => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Salvo com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}
			else {
				this.service.patch(body, this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Editado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					if (typeof error.error === 'string') {
						this.toastsService.toastError(error.error);
					}
					else if (error.error.errors) {
						let errorMessage = ""
						Object.getOwnPropertyNames(error.error.errors).forEach((field) => {
							errorMessage += error.error.errors[field]
						})

						this.toastsService.toastError(errorMessage);
					}
					else {
						this.toastsService.toastError("Revise todos os dados e tente novamente!");
					}
				});
			}

		}
		else {
			this.validateAllFormFields(this.form);
		}
	}

	campoValido(nomeCampo) {
		return !this.form.get(nomeCampo).valid && (this.form.get(nomeCampo).dirty || this.form.get(nomeCampo).touched)
	}

	campoValidoEx(nomeCampo) {
		return !this.extraForm.get(nomeCampo).valid && (this.extraForm.get(nomeCampo).dirty || this.extraForm.get(nomeCampo).touched)
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => { 
			const control = formGroup.get(field);            
			if (control instanceof FormControl) {            
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {       
				this.validateAllFormFields(control);           
			}
		});
	}

	remove() {
		swal({
			text: 'Deseja realmente deletar esse Hóspede?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Não!',
			confirmButtonText: 'Sim, deletar!'
		}).then((result) => {
			if (result.value) {
				this.isFormLoading = true;
				this.service.delete(this.selected.codigo).subscribe(() => {
					this.closeModal('modal');
					this.toastsService.toastSuccess("Deletado com sucesso!");
					this.load();
				}, error => {
					this.isFormLoading = false;
					this.toastsService.toastError("Tente novamente!");
				});
			}
		});
	}
}
