import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})

export class ReservaHospedesService {

	constructor(private http: HttpClient) { }

	private authHeader(): string {
		return `Bearer ${JSON.parse(localStorage.getItem('User')).token}`;
	}

	public get(CodReserva) {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.get(`${environment.apiUrl}/cadastro/ReservaHospedes?CodReserva=${CodReserva}`, { headers });
	}

	public post(hospede) {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.post(`${environment.apiUrl}/cadastro/ReservaHospedes`, hospede, { headers });
	}

	public patch(hospede, id) {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.patch(`${environment.apiUrl}/cadastro/ReservaHospedes/${id}`, hospede, { headers });
	}

	public delete(id) {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.delete(`${environment.apiUrl}/cadastro/ReservaHospedes/${id}`, { headers });
	}
}
