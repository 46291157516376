import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from './app.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ToastaModule } from 'ngx-toasta';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalService } from './shared/_services/index';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgmCoreModule } from '@agm/core';
import { DxVectorMapModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { CKEditorModule } from 'ngx-ckeditor';
import { ChartsModule } from 'ng2-charts';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { DashboardComponent } from './dashboard/dashboard.component';
import { CommonDataService } from './common-data.service';
import { Modal1Component } from './shared/_directives/index';
import { LayoutComponent } from './shared/_layout/layout/layout.component';
// NEW
import { HttpRequestInterceptor } from './api/interceptors/requestions.interceptor';
import { NgSelectModule } from '@ng-select/ng-select';

import { LoginComponent } from './login/login.component';
import { AlterarsenhaComponent } from './alterarsenha/alterarsenha.component';
import { AreasComponent } from './components/areas/areas.component';
import { TableComponent } from './shared/table/table.component';
import { MenuComponent } from './shared/_layout/menu/menu.component';
import { SubmenuComponent } from './shared/_layout/menu/submenu/submenu.component';
import { PageTitleComponent } from './shared/page-title/page-title.component';
import { CategoriaDeAtividadesComponent } from './components/categoria-de-atividades/categoria-de-atividades.component';
import { CategoriaDeUnidadesComponent } from './components/categoria-de-unidades/categoria-de-unidades.component';
import { StatusDosAgendamentosComponent } from './components/status-dos-agendamentos/status-dos-agendamentos.component';
import { TiposDeServicoComponent } from './components/tipos-de-servico/tipos-de-servico.component';
import { FuncionariosComponent } from './components/funcionarios/funcionarios.component';
import { AtividadesComponent } from './components/atividades/atividades.component';
import { ServicosComponent } from './components/servicos/servicos.component';
import { UnidadesDeHabitacaoComponent } from './components/unidades-de-habitacao/unidades-de-habitacao.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { HospedesComponent } from './components/hospedes/hospedes.component';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { TarefasComponent } from './components/tarefas/tarefas.component';
import { DepartamentosComponent } from './components/departamentos/departamentos.component';
import { ProgramacaoComponent } from './components/programacao/programacao.component';
import { ReservasComponent } from './components/reservas/reservas.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ExcecoesComponent } from './components/excecoes/excecoes.component';
// finish

import { AppInitService } from './api/services/app-init.service';
import { AgendamentosComponent } from './components/agendamentos/agendamentos.component';

export function initializeApp(appInitService: AppInitService, injector: Injector) {
    return () => appInitService.initializeApp(injector);
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LayoutComponent,
    Modal1Component,
    LoginComponent,
    AlterarsenhaComponent,
    AreasComponent,
    TableComponent,
    MenuComponent,
    SubmenuComponent,
    PageTitleComponent,
    CategoriaDeAtividadesComponent,
    CategoriaDeUnidadesComponent,
    StatusDosAgendamentosComponent,
    TiposDeServicoComponent,
    FuncionariosComponent,
    AtividadesComponent,
    ServicosComponent,
    UnidadesDeHabitacaoComponent,
    UsuariosComponent,
    HospedesComponent,
    ChecklistComponent,
    TarefasComponent,
    DepartamentosComponent,
    ProgramacaoComponent,
    ReservasComponent,
    CheckoutComponent,
    ExcecoesComponent,
    AgendamentosComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    BrowserAnimationsModule,
    ToastaModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
    NgxDatatableModule,
    ColorPickerModule,
    NgScrollbarModule,
    NgxChartsModule,
    AgmCoreModule.forRoot({ apiKey: "AIzaSyDjVoaCW3PAn52C7WPpJ7NBBqU1_TUfnSI" }),
    DxVectorMapModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    CKEditorModule,
    NgbModule,
    NgbModalModule,
    NgxMaskModule.forRoot(),
    NgSelectModule
  ],
  providers: [
    CommonDataService, 
    ModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AreasComponent,
    CategoriaDeAtividadesComponent,
    AtividadesComponent,
    CategoriaDeUnidadesComponent,
    TiposDeServicoComponent,
    FuncionariosComponent,
    HospedesComponent,
    ServicosComponent,
    UnidadesDeHabitacaoComponent,
    StatusDosAgendamentosComponent,
    ChecklistComponent,
    DepartamentosComponent,
    TarefasComponent,
    ProgramacaoComponent,
    ReservasComponent,
    CheckoutComponent,
    ExcecoesComponent,
    UsuariosComponent,
    AgendamentosComponent
  ],
})
export class AppModule { }
