import { Injectable } from '@angular/core';
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';

@Injectable({
    providedIn: 'root'
})

export class ToastsService {
    constructor(private toastaService: ToastaService, private toastaConfig: ToastaConfig) {
        this.toastaConfig.theme = 'material';
        this.toastaConfig.position = 'top-right';
    }

    public toastSuccess(title, msg = '')
    {
        var toastOptions: ToastOptions = {
            title: title,
            msg: msg,
            showClose: true,
            timeout: 6000,
            theme: 'bootstrap'            
        };

        this.toastaService.success(toastOptions);

        // if (shortCutFunction == "success")
        // {
        //     this.toastaService.success(toastOptions);
        // }

        // if (shortCutFunction == "info")
        // {
        //     this.toastaService.info(toastOptions);
        // }

        // if (shortCutFunction == "warning") {
        //     this.toastaService.warning(toastOptions);
        // }

        // if (shortCutFunction == "error") {
        //     this.toastaService.error(toastOptions);
        // }
    }

    public toastError(title, msg = '')
    {
        var toastOptions: ToastOptions = {
            title: title,
            msg: msg,
            showClose: true,
            timeout: 10000,
            theme: 'bootstrap'            
        };

        this.toastaService.error(toastOptions);
    }

    public toastWarning(title, msg = '')
    {
        var toastOptions: ToastOptions = {
            title: title,
            msg: msg,
            showClose: true,
            timeout: 10000,
            theme: 'bootstrap'            
        };

        this.toastaService.warning(toastOptions);
    }
}
