import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})

export class UserService {
	public userLoged = false

	constructor(private http: HttpClient) { }

	private authHeader(): string {
		return `Bearer ${JSON.parse(localStorage.getItem('User')).token}`;
	}

	public auth(email: string, senha: string) {
		let dados = {
			email: email,
			senha: senha,
			tipoLogin: 1
		};

		return this.http.post(`${environment.apiUrl}/sistema/Usuarios/auth`, dados);
	}

	public refreshToken(user: string, token: string, refreshToken: string) {
		let dados = {
			user: user,
			token: token,
			refreshToken: refreshToken
		};

		return this.http.post(`${environment.apiUrl}/sistema/Usuarios/refresh-token`, dados);
	}

	public get() {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.get(`${environment.apiUrl}/sistema/usuarios`, { headers });
	}

	public post(body) {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.post(`${environment.apiUrl}/sistema/usuarios`, body, { headers });
	}

	public patch(body, id) {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', this.authHeader());

		return this.http.patch(`${environment.apiUrl}/sistema/usuarios/${id}`, body, { headers });
	}

	public sendEmailToResetPassword(email: string) {
		let dados = {
			email: email
		};

		return this.http.post(`${environment.apiUrl}/sistema/Usuarios/sendemailtoresetpassword`, dados);
	}

	public updatePassword(token: string, senha: string) {
		let dados = {
			token: token,
			senha: senha
		};

		return this.http.post(`${environment.apiUrl}/sistema/Usuarios/updatepassword`, dados);
	}

	public setUser(data: any) {
		if(data) {
			this.userLoged = true;
		}
		else {
			this.userLoged = false;
		}
		localStorage.setItem("User", JSON.stringify(data));
	}

	public getUser() {
		let user = JSON.parse(localStorage.getItem("User"));
		if(user) {
			this.userLoged = true;
		}
		else {
			this.userLoged = false;
		}
		return user;
	}
}
